<template>
<div>
    <v-card v-if="profile" class="mx-auto  animate__animated animate__fadeIn" max-width="600" outlined>
        <div class="row">
            <div class="col-8">

                <v-list-item three-line>
                    <v-list-item-content>
                        <div class="text-overline mb-4">
                            โปรไฟล์
                        </div>
                        <v-list-item-title class=" p_name mb-1">
                            {{profile.fullname}}
                        </v-list-item-title>

                        <v-list-item-title class="mb-1">
                            <div class="p_b">เบอร์โทร</div>
                            {{profile.phone}}
                        </v-list-item-title>

                        <v-list-item-title class="mb-1">
                            <div class="p_b">มือถือ</div>
                            {{profile.mobile}}
                        </v-list-item-title>

                        <v-list-item-title class="mb-1">
                            <div class="p_b">อีเมล์</div>
                            {{profile.email}}
                        </v-list-item-title>

                        <v-list-item-title class="mb-1">
                            <div class="p_b">Username</div>
                            {{profile.username}}
                        </v-list-item-title>

                    </v-list-item-content>

                </v-list-item>

            </div>
            <div class="col-4 text-center pt-3">

                <div class="pt-3">
                    <v-avatar>
                        <img :src="profile.picture">
                    </v-avatar>
                </div>

                <div class="mt-5 mb-5">
                    <v-file-input label="อัปโหลดรูป" size="small" dense v-model="pictureUpload"></v-file-input>
                </div>

                <v-progress-circular v-if="showLoading" indeterminate color="primary"></v-progress-circular>

                <div class="mt-2 mb-2 uploadResult" v-if="uploadResult" v-html="uploadResult"></div>

            </div>
        </div>

        <v-card-actions>

        </v-card-actions>
    </v-card>
</div>
</template>

<script>
export default {

    components: {

    },

    data: () => ({
        profile: null,
        pictureUpload: null,
        showLoading: false,
        picture: null,
        uploadResult: null

    }),

    computed: {

    },

    watch: {
        pictureUpload: function (val) {
            console.log(val)
            let $vm = this
            $vm.showLoading = true
            let formData = new FormData();
            formData.append("file", val);

            console.log(formData);

            if (this.token()) {
                var url = "upload/picture_one?time=" + Math.random();
                console.log(this.$API + url);
                this.$http
                    .post(url, this.formData(formData), {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function (res) {
                        console.log(res.data);
                        console.log("SUCCESS!!");
                        if (res.data.uploadResult) {
                             $vm.uploadResult = res.data.uploadResult.msg;
                             if(res.data.uploadResult.picture) { 
                            $vm.profile.picture  = res.data.uploadResult.picture_path;
                            $vm.updatePicture(res.data.uploadResult.picture)
                             }
                        }

                        $vm.showLoading = null;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

        },
    },
    methods: {

         updatePicture(picture) {
            let $vm = this;

            if (this.token()) {
                 let formData = new FormData();
                formData.append("picture", picture);

                var url = "user/update_picture?time=" + Math.random();
                console.log(url);
                this.$http
                    .post(url, this.formData(formData))
                    .then(function (res) {
                        console.log(res.data); 
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },


        getProfile() {
            let $vm = this;

            if (this.token()) {
                var url = "user/profile?time=" + Math.random();
                console.log(url);
                this.$http
                    .post(url, this.formData())
                    .then(function (res) {
                        console.log(res.data);
                        if (res.data.profile) {
                            $vm.profile = res.data.profile
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },

    },
    created() {
        this.getProfile()
    },
};
</script>
